<template>
  <div>
    <swiper />
    <wcard />
    <mall />
    <promote />
  </div>
</template>

<script>
  import swiper from '@/components/swiper'
  import wcard from '@/components/wcard'
  import mall from '@/components/mall'
  import promote from '@/components/promote'
  export default {
    components: {
      swiper,
      wcard,
      mall,
      promote
    }
  }
</script>
